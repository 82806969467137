import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const CheckoutModal = (props: { show: boolean }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(props.show);

  return (
    <Modal show={show} keyboard={false} size='sm' centered onHide={() => setShow(!show)}>
      <Modal.Body>{t('checkout_text')}</Modal.Body>
    </Modal>
  );
};
