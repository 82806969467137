import { faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useAccount } from 'src/context';

export const Header = () => {
  const { account } = useAccount();
  const { t, i18n } = useTranslation();
  const locales = [
    { name: 'English', value: 'en', pattern: /^en/i },
    { name: '正體中文', value: 'zh-TW', pattern: /^zh/i },
    { name: '日本語', value: 'ja', pattern: /^ja/i },
  ];

  const changeLanguage = (lang: string): void => {
    void i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    Cookies.set('locale', lang);
    localStorage.setItem('locale', lang);
  };

  const isCurrentLanguage = (pattern: RegExp): boolean => {
    return pattern.test(i18n.languages[0]);
  };

  const list = process.env.REACT_APP_CUSTOMER_ALLOW_LIST?.split(',');

  return (
    <>
      <Navbar collapseOnSelect bg='dark' expand='lg' fixed='top' variant='dark'>
        <Container>
          <Navbar.Brand href='/'>{t('platformName')}</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {account && (
                <>
                  <Nav.Link href='/transactions'>{t('transaction')}</Nav.Link>
                  {list?.some((x) => x === account.email.toLowerCase()) && (
                    <Nav.Link href='/customers'>{t('distributionCustomers')}</Nav.Link>
                  )}
                </>
              )}
            </Nav>
            {!account && <Nav.Link href='/login'>{t('signIn')}</Nav.Link>}
            {account && (
              <NavDropdown
                title={
                  <span className='link-secondary'>
                    <FontAwesomeIcon icon={faUser} /> {account?.name}
                  </span>
                }
                id='account-dropdown'
              >
                <NavDropdown.Item href='/logout'>{t('signOut')}</NavDropdown.Item>
              </NavDropdown>
            )}
            <NavDropdown
              title={
                <span className='link-secondary'>
                  <FontAwesomeIcon icon={faGlobe} />{' '}
                </span>
              }
              id='locale-dropdown'
            >
              {locales.map(({ name, pattern, value }) => {
                return (
                  <NavDropdown.Item
                    key={name}
                    className={isCurrentLanguage(pattern) ? 'active' : ''}
                    onClick={() => changeLanguage(value)}
                  >
                    {name}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet context={{ account }} />
    </>
  );
};
