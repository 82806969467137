import { addDays, format, parseISO } from 'date-fns';
import { produce } from 'immer';
import React, { BaseSyntheticEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PurchaseService } from 'src/apis';
import { DatePickerInput, ExtraInfoForm, ModalFooter, ModuleForm, PlanForm } from 'src/components';
import { License, PurchaseOrder, PurchaseOrderItem, genDescript } from 'src/types';
import { isFreePlan } from 'src/utils/productUtils';

type Option = {
  currentLicense: License;
  show: boolean;
  hide: () => void;
  setCheckout: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddonModal = ({ currentLicense, show, hide, setCheckout }: Option) => {
  const [nextStep, setNextStep] = useState(false);
  const initPlan: string = currentLicense.plan;
  const [license, setLicense] = useState(currentLicense);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
    instanceId: license.instanceId,
    items: [],
    endDate: '',
  });
  const [invalid, setInvalid] = useState({ plan: false, renewEndDate: false });
  const { t } = useTranslation();
  const handleChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target;
    if (name === 'plan') {
      setValid(value === undefined ? value : value ? false : true);
    }

    setLicense(
      produce((draft) => {
        if (name === 'plan') {
          draft.plan = value;
          return;
        }
        const module = draft?.modules.find(({ sku }) => sku === name);
        if (module) {
          module.enabled = !module.enabled;
        }
        const extraInfoItem = draft?.extraInfo.find(({ sku }) => sku === name);
        if (extraInfoItem) {
          extraInfoItem!.quantity = value;
        }
      }),
    );
  };

  const handleNextStep = () => {
    const items: PurchaseOrderItem[] = [];
    let endDate = license.expirationDate;
    if (isFreePlan(initPlan)) {
      if (license.plan === initPlan) {
        setValid(true);
        return;
      }

      setValid(false);
      items.push({
        sku: license.plan,
        price: 0,
        quantity: 1,
        description: genDescript(t, license.plan, 1, 0),
      });
      endDate = purchaseOrder.endDate;
    }

    if (
      !(license.plan !== undefined && license.plan !== initPlan) &&
      !license.modules.some((x) => x.enabled) &&
      !license.extraInfo.some((x) => x.quantity)
    ) {
      alert(t('addonError'));
      return;
    }

    items.push(
      ...license.modules
        .filter((x) => x.enabled)
        .map((x) => ({
          sku: x.sku,
          price: 0,
          quantity: 1,
          description: genDescript(t, x.sku, 1, 0),
        })),
      ...license.extraInfo
        .filter((x) => x.quantity > 0)
        .map((x) => ({
          sku: x.sku,
          price: 0,
          quantity: x.quantity,
          description: genDescript(t, x.sku, x.quantity, 0),
        })),
    );

    setPurchaseOrder({
      instanceId: license.instanceId,
      items: items,
      endDate: endDate,
    });
    setNextStep(true);
  };

  const handlePriceChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target;
    setPurchaseOrder(
      produce((draft) => {
        const item = draft?.items.find(({ sku }) => sku === name);
        if (item) {
          item.price = value;
          item.description = genDescript(t, item.sku, item.quantity, item.price);
        }
      }),
    );
  };

  const setValid = (plan?: boolean, renewEndDate?: boolean) => {
    const fields = {
      plan: plan === undefined ? invalid.plan : plan,
      renewEndDate: renewEndDate === undefined ? invalid.renewEndDate : renewEndDate,
    };
    setInvalid(fields);
  };

  const setEndDate = (endDate: Date) => {
    setValid(undefined, false);
    setPurchaseOrder((prev: PurchaseOrder) => ({
      ...prev,
      endDate: format(endDate, 'yyyy-MM-dd'),
    }));
  };

  const handleHide = () => {
    hide();
    setNextStep(false);
  };

  const submit = async () => {
    if (!purchaseOrder.endDate) {
      setValid(undefined, true);
      return;
    }

    setValid(undefined, false);
    const success = await PurchaseService.createAddon(license.instanceId, purchaseOrder);
    if (success) {
      handleHide();
      setCheckout(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      backdrop='static'
      keyboard={false}
      size='lg'
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('addon')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFreePlan(initPlan) && nextStep && (
          <Form className='mb-5'>
            <Row>
              <Form.Label column md='auto' className='input-required'>
                {t('renewEndDate')}
              </Form.Label>
              <Col xs='4'>
                <DatePickerInput
                  date={purchaseOrder.endDate ? parseISO(purchaseOrder.endDate) : undefined}
                  minDate={addDays(new Date(), 1)}
                  setDate={setEndDate}
                  invalid={invalid.renewEndDate}
                />
              </Col>
            </Row>
          </Form>
        )}
        {isFreePlan(initPlan) && (
          <>
            {t('plan')}
            <hr />
            <PlanForm
              plan={license.plan}
              modifiableQuantity={!nextStep}
              items={purchaseOrder.items}
              optionPlans={['vital_crm_addon_sme', 'vital_crm_addon_enterprise']}
              invalid={invalid.plan}
              handleChange={handleChange}
              handlePriceChange={handlePriceChange}
            />
          </>
        )}
        {t('module')}
        <hr />
        <ModuleForm
          modules={license.modules}
          isNextStep={nextStep}
          modifiableQuantity={!nextStep}
          items={purchaseOrder.items}
          handleChange={handleChange}
          handlePriceChange={handlePriceChange}
        />
        {t('extraInfo')}
        <hr />
        <ExtraInfoForm
          extraInfo={license.extraInfo}
          isNextStep={nextStep}
          showNoExtraInfo={license?.extraInfo.every((x) => nextStep && x.quantity <= 0)}
          modifiableQuantity={!nextStep}
          items={purchaseOrder.items}
          handleChange={handleChange}
          handlePriceChange={handlePriceChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <ModalFooter
          nextStep={nextStep}
          totalGrandTotal={purchaseOrder.items
            .map((x) => x.price)
            ?.reduce((prev, current) => Number(prev) + Number(current), 0)
            .toLocaleString()}
          handleNextStep={handleNextStep}
          handlePreviousStep={() => setNextStep(false)}
          handleConfirm={submit}
          handleCancel={handleHide}
        />
      </Modal.Footer>
    </Modal>
  );
};
