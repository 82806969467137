import { BaseSyntheticEvent } from 'react';
import { FormControl } from 'react-bootstrap';

type Option = {
  name: string;
  value: number;
  onChange: (event: BaseSyntheticEvent) => void;
};

export const FormNumberInput = ({ name, value, onChange }: Option) => {
  return <FormControl type='number' name={name} value={value} min='0' onChange={onChange} />;
};
