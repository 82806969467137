import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

const axiosRequestConfig: AxiosRequestConfig = {
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const axiosFactory = (config: AxiosRequestConfig = axiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.response.use(
    (response) => {
      // 使用 /api/account/login?returnUrl 方式，在登入後導回原本網站存取頁面，避免導向後端 API 回傳資料顯示，
      const location = response.headers['location'];
      if (location) {
        window.location.assign(`/api/account/login?returnUrl=${window.location.pathname}`);
      }
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 404 || error.response?.status === 403) {
        window.location.assign('/accessDenied');
      }
      if (error.response?.status === 401) {
        window.location.assign(`/api/account/login?returnUrl=${window.location.pathname}`);
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};
