import { format, parseISO } from 'date-fns';
import { BaseSyntheticEvent } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SortIcon } from 'src/components';
import { Transaction } from 'src/types';

type Option = {
  isAdmin: boolean;
  currentSortField: string;
  currentSortRule: string;
  transactions: Transaction[];
  setSort: (sortField: string, sortRule?: string) => void;
};

export const TransactionTable = ({
  isAdmin,
  currentSortField,
  currentSortRule,
  transactions,
  setSort,
}: Option) => {
  const { t } = useTranslation();
  const sortableTableHeader = ['orderNumber', 'createdAt', 'expirationDate'];
  if (isAdmin) {
    sortableTableHeader.push('accountName');
  }

  const handleSort = (event: BaseSyntheticEvent) => {
    const id = event.target.id;
    let sortRule: string | undefined = currentSortRule;
    let sortField = currentSortField;
    if (sortField === undefined || sortRule === undefined) {
      sortField = id;
      sortRule = 'Asc';
    } else if (sortField === id) {
      sortRule = sortRule === 'Asc' ? 'Desc' : undefined;
    } else {
      sortField = id;
      sortRule = 'Asc';
    }

    setSort(sortField, sortRule);
  };

  return (
    <Table hover size='sm'>
      <thead>
        <tr>
          {sortableTableHeader.map((value) => {
            return (
              <th key={value} id={value} onClick={handleSort}>
                {t(value)}
                <span className='fa-layers fa-fw'>
                  <SortIcon state={currentSortField === value ? currentSortRule : undefined} />
                </span>
              </th>
            );
          })}
          <th>{t('customerCompany')}</th>
          <th>{t('customerName')}</th>
          <th>{t('program')}</th>
          <th className='text-end'>{t('amount')}(NT$)</th>
        </tr>
      </thead>
      <tbody>
        {transactions?.map(
          ({
            id,
            orderNumber,
            createdAt,
            expirationDate,
            account,
            customer,
            programName,
            grandTotal,
          }) => {
            return (
              <tr key={id}>
                <td>
                  <Link to={`/salesOrders/${orderNumber}`}>{orderNumber}</Link>
                </td>
                <td>
                  {createdAt && (
                    <span title={format(parseISO(createdAt), 'yyyy-MM-dd')}>
                      {format(parseISO(createdAt), 'yyyy-MM-dd')}
                    </span>
                  )}
                </td>
                <td>
                  {expirationDate && (
                    <span title={format(parseISO(expirationDate), 'yyyy-MM-dd')}>
                      {format(parseISO(expirationDate), 'yyyy-MM-dd')}
                    </span>
                  )}
                </td>
                {isAdmin && (
                  <td>
                    <span title={account.email}>{account.name || account.email}</span>
                  </td>
                )}
                <td>{customer.company}</td>
                <td>{customer.name}</td>
                <td>{programName || '-'}</td>
                <td className='text-end'>{grandTotal.toLocaleString()}</td>
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
};
