import { format, parseISO } from 'date-fns';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { License } from 'src/types';
import { getProductName, isFreePlan } from 'src/utils';
import { ExtraInfoTable } from './extraInfoTable';
import { ModuleTable } from './moduleTable';

type Options = {
  licenses?: License[];
  handleClick: (id: string, action: string) => void;
};

export const OrderDetailTable = ({ licenses, handleClick }: Options) => {
  const { t } = useTranslation();

  if (!licenses?.length) {
    return <span>{t('noOrderDetail')}</span>;
  }

  return (
    <Table className='table table-hover table-bordered'>
      <thead data-toggle='collapse' data-target='#detail'>
        <tr>
          <th>{t('planType')}</th>
          <th>{t('accountLicenses')}</th>
          <th>{t('storage')}</th>
          <th>{t('expirationDate')}</th>
          <th>{t('module')}</th>
          <th>{t('extraInfo')}</th>
          <th>{t('action')}</th>
        </tr>
      </thead>
      <tbody id='detail'>
        {licenses?.map(
          ({ plan, account, storage, expirationDate, modules, extraInfo, instanceId }, index) => {
            return (
              <tr key={index}>
                <td>{t(`sku:${getProductName(plan)}.${plan}`)}</td>
                <td>{account}</td>
                <td>{storage}</td>
                <td>{format(parseISO(expirationDate), 'yyyy-MM-dd')}</td>
                <td>
                  <ModuleTable modules={modules} />
                </td>
                <td>
                  <ExtraInfoTable extraInfo={extraInfo} />
                </td>
                <td>
                  <Button
                    className='m-1 bg-success'
                    size='sm'
                    onClick={() => handleClick(instanceId, 'addon')}
                  >
                    {t('addon')}
                  </Button>
                  <Button
                    className='m-1'
                    size='sm'
                    disabled={isFreePlan(plan)}
                    onClick={() => handleClick(instanceId, 'renew')}
                  >
                    {t('renew')}
                  </Button>
                </td>
              </tr>
            );
          },
        )}
      </tbody>
    </Table>
  );
};
