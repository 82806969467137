import { BaseSyntheticEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ExtraInfo, PurchaseOrderItem } from 'src/types';
import { getProductName } from 'src/utils';
import { FormNumberInput } from './formNumberInput';
import { SelectOptions } from './selectOptions';

type Options = {
  extraInfo: ExtraInfo[];
  isNextStep: boolean;
  showNoExtraInfo: boolean;
  modifiableQuantity: boolean;
  items: PurchaseOrderItem[];
  handleChange: (event: BaseSyntheticEvent) => void;
  handlePriceChange: (event: BaseSyntheticEvent) => void;
};

export const ExtraInfoForm = ({
  extraInfo,
  isNextStep,
  showNoExtraInfo,
  modifiableQuantity,
  items,
  handleChange,
  handlePriceChange,
}: Options) => {
  const { t } = useTranslation();

  return (
    <Form className='px-4 mb-5'>
      {showNoExtraInfo && (
        <Row>
          <span className='text-muted'>{t('noExtraInfo')}</span>
        </Row>
      )}
      {extraInfo
        .filter((x) => !isNextStep || x.quantity > 0)
        .map(({ quantity, sku }) => {
          let input = !modifiableQuantity ? (
            <span className='text-primary ms-3'>{quantity}</span>
          ) : (
            <Col md='auto'>
              <FormNumberInput name={sku} value={quantity} onChange={handleChange} />
            </Col>
          );
          if (modifiableQuantity && (sku === 'vital_crm_sms' || sku === 'vital_crm_point')) {
            input = (
              <Col md='auto'>
                <SelectOptions
                  name={sku}
                  value={quantity}
                  handleChange={handleChange}
                  type={
                    sku === 'vital_crm_sms' ? 'points' : sku === 'vital_crm_point' ? 'credits' : ''
                  }
                />
              </Col>
            );
          }

          return (
            <Row key={sku} className='row-hover'>
              <Form.Label column xs='4'>
                {t(`sku:${getProductName(sku)}.${sku}`)}
                {!modifiableQuantity && input}
              </Form.Label>
              {modifiableQuantity && input}
              {!modifiableQuantity ? (
                <>
                  <Form.Label column md='auto'>
                    {t('amount')}
                  </Form.Label>
                  <Col>
                    <FormNumberInput
                      name={sku}
                      value={items.find((x) => x.sku === sku)?.price ?? 0}
                      onChange={handlePriceChange}
                    />
                  </Col>
                  <Form.Label column>{t('dollars')}</Form.Label>
                </>
              ) : null}
            </Row>
          );
        })}
    </Form>
  );
};
