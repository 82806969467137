import { format } from 'date-fns';
import { useState } from 'react';
import { FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import { Calendar } from 'react-date-range';
import { useTranslation } from 'react-i18next';

type Option = {
  date?: Date;
  minDate: Date;
  setDate: (date: Date) => void;
  invalid: boolean;
};

export const DatePickerInput = (props: Option) => {
  const [date, setDate] = useState(props.date ?? props.minDate);
  const [input, setInput] = useState(props.date ? format(date as Date, 'yyyy-MM-dd') : '');
  const { t } = useTranslation();
  const handleChange = (date: Date) => {
    setDate(date);
    props.setDate(date);
    setInput(format(date as Date, 'yyyy-MM-dd'));
  };

  const CalendarPopover = (
    <Popover className='mw-100'>
      <Calendar date={date} onChange={handleChange} minDate={props.minDate} />
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger='click' placement='bottom-start' overlay={CalendarPopover} rootClose>
        <FormControl
          type='text'
          value={input}
          autoComplete='off'
          readOnly
          className={props.invalid ? 'border-danger' : ''}
        />
      </OverlayTrigger>
      {props.invalid && <div className='mt-1 text-danger w-100'>{t('required_text')}</div>}
    </>
  );
};
