import definitions from 'src/definitions/skus.json';

export const getProductName = (sku: string) => {
  if (sku.match(/^videgree/)) {
    return 'crm';
  }

  return sku.match(/_([a-z]*)_/)?.[1];
};

export const isFreePlan = (sku: string) => {
  return sku ? definitions.some((x) => x.Type === 'FreeTrailPlan' && x.Sku === sku) : false;
};
