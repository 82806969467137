import { format } from 'date-fns';
import { useState } from 'react';
import { Button, FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import { DateRangePicker, Range, StaticRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';

type Option = {
  staticRanges: { label: string; startDate?: Date; endDate?: Date }[];
  setDate: (startDate: string, endDate: string) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled: boolean;
};

export const DateRangePickerInput = (props: Option) => {
  const { t } = useTranslation();
  const today = new Date();
  const defaultRange = { startDate: today, endDate: today, key: 'selection' };
  const [range, setRange] = useState<Range>(defaultRange);
  const [input, setInput] = useState('');
  const defaultStaticRanges: StaticRange[] = props.staticRanges.map((x) => {
    return {
      label: t(x.label)!,
      range: () => ({
        startDate: x.startDate || today,
        endDate: x.endDate || today,
      }),
      isSelected: () => false,
      hasCustomRendering: true,
    };
  });

  const handleChange = (range: Range) => {
    setRange(range);
    props.setDate(
      format(range.startDate as Date, 'yyyy-MM-dd'),
      format(range.endDate as Date, 'yyyy-MM-dd'),
    );
    setInput(
      format(range.startDate as Date, 'yyyy-MM-dd') +
        '~' +
        format(range.endDate as Date, 'yyyy-MM-dd'),
    );
  };

  const clear = () => {
    setInput('');
    setRange(defaultRange);
    props.setDate('', '');
    close();
  };

  const close = () => {
    document.body.click();
  };

  const renderStaticRangeLabel = (props: StaticRange) => {
    return (
      <span className='random-date-string'>
        <b>{props.label}</b>
      </span>
    );
  };

  const ButtonCombo = (
    <>
      <Button onClick={close} className='ms-3' size='sm'>
        {t('apply')}
      </Button>
      <Button onClick={clear} className='ms-3' size='sm'>
        {t('clear')}
      </Button>
    </>
  );

  const DateRangePickerPopover = (
    <Popover className='mw-100'>
      <DateRangePicker
        ranges={[range]}
        onChange={(item) => handleChange(item.selection)}
        months={2}
        direction='horizontal'
        showMonthAndYearPickers={false}
        dateDisplayFormat={'yyyy-MM-dd'}
        renderStaticRangeLabel={renderStaticRangeLabel}
        staticRanges={defaultStaticRanges}
        inputRanges={[]}
        footerContent={ButtonCombo}
        maxDate={props.maxDate}
        minDate={props.minDate}
      />
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom-start'
      overlay={DateRangePickerPopover}
      rootClose
    >
      <FormControl
        type='text'
        value={input}
        autoComplete='off'
        disabled={props.disabled}
        readOnly
      />
    </OverlayTrigger>
  );
};
