import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Header, ProtectedRoute } from './components';
import { AppContextProvider } from './context';
import {
  AccessDenied,
  Customer,
  Customers,
  Login,
  Logout,
  SalesOrder,
  Transactions,
} from './pages';
import './scss/main.scss';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

export const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('platformName');
  }, [t]);

  return (
    <div>
      <Routes>
        <Route path='/'>
          <Route index element={<Login />} />
          <Route path='login' element={<Login />} />
          <Route path='logout' element={<Logout />} />
          <Route path='accessDenied' element={<AccessDenied />} />
          <Route element={<AppContextProvider />}>
            <Route element={<Header />}>
              <Route path='transactions' element={<Transactions />} />
              <Route path='salesOrders/:salesOrderId' element={<SalesOrder />} />
              <Route element={<ProtectedRoute />}>
                <Route path='customers' element={<Customers />} />
                <Route path='customers/:customerId' element={<Customer />} />
              </Route>
            </Route>
          </Route>
          <Route path='*' element={<Navigate to='login' />} />
        </Route>
      </Routes>
    </div>
  );
};
