import { differenceInMonths, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerService, LicenseService } from 'src/apis';
import { ProgressBar, TableRow } from 'src/components';
import { useAccount } from 'src/context';
import { Customer as CustomerType, License } from 'src/types';
import { isFreePlan } from 'src/utils';
import { AddonModal } from './addonModal';
import { CheckoutModal } from './checkoutModal';
import { OrderDetailTable } from './orderDetailTable';
import { RenewModal } from './renewModal';

export const Customer = () => {
  const { account } = useAccount();
  const [customer, setCustomer] = useState<CustomerType>();
  const [loading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState<License[]>();
  const [modifyingLicense, setModifyingLicense] = useState<License>();
  const [singleStep, setSingleStep] = useState(false);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState('');
  const [checkout, setCheckout] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = Date.now();
  let params = useParams();

  const fetchCustomer = useCallback(async () => {
    setLoading(true);
    if (!params.customerId) {
      return;
    }
    const data = await CustomerService.get(params.customerId);
    setCustomer(data);
    setLoading(false);
  }, [params.customerId]);

  const fetchLicense = useCallback(async () => {
    setLoading(true);
    if (!customer?.email) {
      return;
    }
    const data = await LicenseService.findByEmail(customer.email);
    setLicenses(data);
    setLoading(false);
  }, [customer?.email]);

  const handleClick = (id: string, action: string) => {
    let license: License = JSON.parse(JSON.stringify(licenses?.find((x) => x.instanceId === id)));
    let singleStep = false;
    if (action === 'addon') {
      // 免費轉付費，重設當前模組狀態為未啟用
      license.modules = isFreePlan(license.plan)
        ? license.modules.map((module) => {
            module.enabled = false;
            return module;
          })
        : license.modules.filter((module) => !module.enabled);
      license?.extraInfo.forEach((x) => (x.quantity = 0));
    }
    if (action === 'renew') {
      singleStep = differenceInMonths(today, parseISO(license.expirationDate)) < 0;
      // 續約原方案
      if (singleStep) {
        license.modules = license.modules.filter((module) => module.enabled);
      } else {
        // 換方案，重設所有授權
        license.plan = '';
        license.modules.forEach((x) => (x.enabled = false));
        license.extraInfo.forEach((x) => (x.quantity = 0));
      }
    }
    setModifyingLicense(license);
    setSingleStep(singleStep);
    setAction(action);
    setShow(true);
  };

  const hide = () => {
    setShow(false);
    setModifyingLicense(undefined);
  };

  const ActionModal = () => {
    if (modifyingLicense) {
      return action === 'addon' ? (
        <AddonModal
          currentLicense={modifyingLicense}
          show={show}
          hide={hide}
          setCheckout={setCheckout}
        />
      ) : action === 'renew' ? (
        <RenewModal
          currentLicense={modifyingLicense}
          singleStep={singleStep}
          show={show}
          hide={hide}
          setCheckout={setCheckout}
        />
      ) : null;
    }

    if (checkout) {
      return <CheckoutModal show={checkout} />;
    }

    return null;
  };

  useEffect(() => {
    if (account) {
      fetchCustomer();
    }
  }, [params.customerId, account, fetchCustomer]);

  useEffect(() => {
    if (account && customer?.email) {
      fetchLicense();
    }
  }, [params.customerId, account, customer?.email, fetchLicense]);

  return (
    <div id='main'>
      <ProgressBar loading={loading} setLoading={setLoading} progress={0} />
      <Button variant='light' onClick={() => navigate(-1)}>
        {t('back')}
      </Button>
      <div className='table-responsive'>
        <Table className='table table-hover'>
          <tbody>
            <TableRow name={t('customerName')} value={customer?.name} />
            <TableRow name={t('customerEmail')} value={customer?.email} />
            <TableRow name={t('customerCompany')} value={customer?.company} />
            <TableRow
              name={t('orderDetail')}
              value={<OrderDetailTable licenses={licenses} handleClick={handleClick} />}
            />
          </tbody>
        </Table>
      </div>
      <ActionModal />
    </div>
  );
};
