import { BaseSyntheticEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ExtraInfo, PurchaseOrderItem } from 'src/types';
import { getProductName } from 'src/utils';
import { FormNumberInput } from './formNumberInput';
import { SelectOptions } from './selectOptions';

type Option = {
  extraInfo: ExtraInfo[];
  items: PurchaseOrderItem[];
  handleChange: (event: BaseSyntheticEvent) => void;
  handlePriceChange: (event: BaseSyntheticEvent) => void;
};

export const PointAddonForm = ({ extraInfo, items, handleChange, handlePriceChange }: Option) => {
  const { t } = useTranslation();

  return (
    <Form className='px-4 mb-5'>
      {extraInfo
        .sort((x, y) => x.sku.localeCompare(y.sku))
        .map(({ sku, quantity }) => {
          return (
            <Row key={sku} className='row-hover'>
              <Form.Label column md='2'>
                {t(`sku:${getProductName(sku)}.${sku}`)}
              </Form.Label>
              <Col md='3'>
                <SelectOptions
                  name={sku}
                  value={quantity}
                  handleChange={handleChange}
                  type={
                    sku === 'vital_crm_sms' ? 'points' : sku === 'vital_crm_point' ? 'credits' : ''
                  }
                />
              </Col>
              <Form.Label column md='1'>
                {t('amount')}
              </Form.Label>
              <Col>
                <FormNumberInput
                  name={sku}
                  value={items.find((x) => x.sku === sku)?.price ?? 0}
                  onChange={handlePriceChange}
                />
              </Col>
              <Form.Label column>{t('dollars')}</Form.Label>
            </Row>
          );
        })}
    </Form>
  );
};
