import { BaseSyntheticEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Module, PurchaseOrderItem } from 'src/types';
import { getProductName } from 'src/utils';
import { FormNumberInput } from './formNumberInput';

type Option = {
  modules: Module[];
  isNextStep: boolean;
  modifiableQuantity: boolean;
  items: PurchaseOrderItem[];
  handleChange: (event: BaseSyntheticEvent) => void;
  handlePriceChange: (event: BaseSyntheticEvent) => void;
};

export const ModuleForm = ({
  modules,
  isNextStep,
  modifiableQuantity,
  items,
  handleChange,
  handlePriceChange,
}: Option) => {
  const { t } = useTranslation();

  return (
    <Form className='px-4 mb-5'>
      {modules.every(({ enabled }) => isNextStep && !enabled) && (
        <Row>
          <span className='text-muted'>{t('noModules')}</span>
        </Row>
      )}
      {modules
        .filter(({ enabled }) => !isNextStep || enabled)
        .map(({ sku, enabled }) => {
          return (
            <Row key={sku} className='row-hover'>
              {!modifiableQuantity ? (
                <>
                  <Form.Label column md='4'>
                    {t(`sku:${getProductName(sku)}.${sku}`)}
                    {enabled && (
                      <mark className='ms-1 bg-success text-white rounded-3 text-nowrap'>
                        {t('enabled')}
                      </mark>
                    )}
                  </Form.Label>
                  <Form.Label column md='auto'>
                    {t('amount')}
                  </Form.Label>
                  <Col>
                    <FormNumberInput
                      name={sku}
                      value={items.find((x) => x.sku === sku)?.price ?? 0}
                      onChange={handlePriceChange}
                    />
                  </Col>
                  <Form.Label column>{t('dollars')}</Form.Label>
                </>
              ) : (
                <>
                  <Form.Label column md='3'>
                    {t(`sku:${getProductName(sku)}.${sku}`)}
                  </Form.Label>
                  <Col>
                    <Form.Switch
                      type='switch'
                      id={sku}
                      label={enabled ? t('enabled') : t('disabled')}
                      name={sku}
                      inline={true}
                      className='ms-3 mt-2'
                      checked={enabled}
                      onChange={handleChange}
                    />
                  </Col>
                </>
              )}
            </Row>
          );
        })}
    </Form>
  );
};
