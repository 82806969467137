import { useTranslation } from 'react-i18next';
import { Module } from 'src/types';
import { getProductName } from 'src/utils';

export const ModuleTable = ({ modules }: { modules: Module[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {modules.map(({ enabled, sku }, index) => {
        const state = enabled
          ? { class: 'bg-success', text: t('enabled') }
          : { class: 'bg-secondary', text: t('disabled') };

        return (
          <div key={index} className='mb-2'>
            <span>{t(`sku:${getProductName(sku)}.${sku}`)}</span>
            <mark className={`text-white ms-1 rounded-3 text-nowrap ${state.class}`}>
              {state.text}
            </mark>
          </div>
        );
      })}
    </>
  );
};
