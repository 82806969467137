import { Navigate, Outlet } from 'react-router-dom';
import { useAccount } from 'src/context';

export const ProtectedRoute = () => {
  const { account } = useAccount();

  if (account === undefined) {
    return null;
  }

  if (
    !process.env.REACT_APP_CUSTOMER_ALLOW_LIST?.split(',').some(
      (x) => x === account?.email.toLowerCase(),
    )
  ) {
    return <Navigate to='/accessDenied' replace />;
  }

  return <Outlet context={{ account }} />;
};
