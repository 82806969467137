import { BaseSyntheticEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderItem } from 'src/types';
import { getProductName } from 'src/utils';
import { FormNumberInput } from './formNumberInput';

type Option = {
  plan: string;
  modifiableQuantity: boolean;
  items: PurchaseOrderItem[];
  optionPlans: string[];
  invalid: boolean;
  handleChange: (event: BaseSyntheticEvent) => void;
  handlePriceChange: (event: BaseSyntheticEvent) => void;
};

export const PlanForm = ({
  plan,
  modifiableQuantity,
  items,
  optionPlans,
  invalid,
  handleChange,
  handlePriceChange,
}: Option) => {
  const { t } = useTranslation();

  return (
    <Form className='mb-5 px-4'>
      <Row className='row-hover'>
        {!modifiableQuantity ? (
          <>
            <Form.Label column md='4'>
              {t(`sku:${getProductName(plan)}.${plan}`)}
            </Form.Label>
            <Form.Label column md='auto'>
              {t('amount')}
            </Form.Label>
            <Col>
              <FormNumberInput
                name={plan}
                value={items.find((x) => x.sku === plan)?.price ?? 0}
                onChange={handlePriceChange}
              />
            </Col>
            <Form.Label column>{t('dollars')}</Form.Label>
          </>
        ) : (
          <>
            <Form.Label column md='2' className='input-required'>
              {t('planType')}
            </Form.Label>
            <Col>
              {optionPlans.map((x) => {
                return (
                  <Form.Check key={x} inline className='mt-2'>
                    <Form.Check.Input
                      id={x}
                      type='radio'
                      name='plan'
                      value={x}
                      className={invalid ? 'border-danger' : ''}
                      checked={plan === x}
                      onChange={handleChange}
                    />
                    <Form.Check.Label htmlFor={x}>
                      {t(`sku:${getProductName(x)}.${x}`)}
                    </Form.Check.Label>
                  </Form.Check>
                );
              })}
              {invalid && <div className='mt-1 text-danger w-100'>{t('required_text')}</div>}
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};
