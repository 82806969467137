import { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomerService } from 'src/apis';
import { Pagination, ProgressBar } from 'src/components';
import { useAccount } from 'src/context';
import { Customer, PagedFilter } from 'src/types';

export const Customers = () => {
  const [customers, setCustomers] = useState<Customer[]>();
  const [loading, setLoading] = useState(true);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [filter, setFilter] = useState<PagedFilter>({ page: 1, limit: 20 });
  const { t } = useTranslation();
  const { account } = useAccount();
  const navigate = useNavigate();

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    const { data, totalItemCount, totalPageCount } = await CustomerService.findAll(filter);
    setCustomers(data);
    setTotalItemCount(totalItemCount);
    setTotalPageCount(totalPageCount);
    setLoading(false);
  }, [filter]);

  const setPage = (page: number) => {
    setFilter((prevParams: PagedFilter) => ({
      ...prevParams,
      page: page,
    }));
  };

  const handleClick = (id: number) => () => {
    navigate(`/customers/${id}`);
  };

  useEffect(() => {
    if (account) {
      fetchCustomers();
    }
  }, [filter, account, fetchCustomers]);

  return (
    <div id='main'>
      <ProgressBar loading={loading} setLoading={setLoading} progress={0} />
      <Pagination
        totalPage={totalPageCount ?? 0}
        currentPage={filter?.page ?? 1}
        pageRange={4}
        onChange={setPage}
      />
      <div className='d-flex justify-content-between'>
        <span>
          {t('totalItemCount')} {totalItemCount?.toLocaleString()} {t('itemUnit')},
          {t('totalPageCount')} {totalPageCount?.toLocaleString()} {t('pageUnit')},
        </span>
      </div>
      <Table hover size='sm'>
        <thead>
          <tr>
            <th>{t('customerName')}</th>
            <th>{t('customerEmail')}</th>
            <th>{t('customerCompany')}</th>
          </tr>
        </thead>
        <tbody>
          {customers?.map(({ id, name, email, company }) => {
            return (
              <tr key={id} id={id.toString()} onClick={handleClick(id)}>
                <td>{name}</td>
                <td>{email}</td>
                <td>{company}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
