import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ProgressBar as DefaultProgressBar } from 'react-bootstrap';

type Options = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  progress: number;
};

export const ProgressBar = ({ loading, setLoading, progress: intiProgress }: Options) => {
  const [progress, setProgress] = useState(intiProgress);

  useEffect(() => {
    if (!loading) {
      setProgress(0);
      return;
    }
    if (progress === 100) {
      setLoading(false);
    }

    const intervalId = setInterval(() => {
      setProgress((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [progress, loading, setLoading]);

  return loading ? <DefaultProgressBar animated now={progress} /> : null;
};
