import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Footer } from './components';
import './i18n';

const root = createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
const footer = createRoot(document.getElementById('footer')!);
footer.render(<Footer />);
