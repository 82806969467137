import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent } from 'react';
import { Col, FormControl } from 'react-bootstrap';

type Option = {
  name: string;
  placeholder: string;
  showClearButton: boolean;
  value: string;
  type: string;
  onChange: (event: BaseSyntheticEvent) => void;
  onClick: (fieldNames: string) => void;
};

export const FormTextInput = ({
  name,
  placeholder,
  type,
  value,
  showClearButton,
  onChange,
  onClick,
}: Option) => {
  return (
    <Col md='auto' className='input-text-clear'>
      <FormControl
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {showClearButton && <FontAwesomeIcon icon={faXmark} onClick={() => onClick(name)} />}
    </Col>
  );
};
