import { BaseSyntheticEvent } from 'react';
import { FormSelect } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Option = {
  name: string;
  value: number;
  type: string;
  handleChange: (event: BaseSyntheticEvent) => void;
};

export const SelectOptions = ({ name, value, type, handleChange }: Option) => {
  const { t } = useTranslation();
  const pointOptions = [500, 1000, 2000, 5000, 10000, 20000];
  const creditOptions = [100000, 200000, 500000, 1000000, 2000000];

  return (
    <FormSelect onChange={handleChange} name={name} value={value}>
      <option>{t('dropdown_placeholder')}</option>
      {type === 'points' &&
        pointOptions.map((option, index) => {
          return (
            <option key={index} value={option}>
              {t('points', { val: option })}
            </option>
          );
        })}
      {type === 'credits' &&
        creditOptions.map((option, index) => {
          return (
            <option key={index} value={option}>
              {t('credits', { val: option })}
            </option>
          );
        })}
    </FormSelect>
  );
};
