import { useTranslation } from 'react-i18next';
import { UserService } from 'src/apis';

export const Login = () => {
  const { t } = useTranslation();

  const signIn = async () => {
    await UserService.login('/transactions');
  };

  return (
    <div className='bg-light rounded-3 text-center position-absolute top-50 start-50 translate-middle p-5'>
      <h2 className='mb-5'>{t('platformName')}</h2>
      <button className='btn btn-lg btn-primary' onClick={signIn}>
        {t('signIn')}
      </button>
    </div>
  );
};
