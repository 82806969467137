import { parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionService } from 'src/apis';
import { Pagination, ProgressBar, SearchForm } from 'src/components';
import { useAccount } from 'src/context';
import { SearchParams, Transaction as TransactionType } from 'src/types';
import { TransactionTable } from './transactionTable';

export const Transactions = () => {
  const [searchParams, setSearchParams] = useState<SearchParams>({ page: 1, limit: 20 });
  const [transactions, setTransactions] = useState<TransactionType[]>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [error, setError] = useState('');
  const { account } = useAccount();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const setPage = (page: number) => {
    setSearchParams((prevParams: SearchParams) => ({
      ...prevParams,
      page: page,
    }));
  };

  const setSort = (sortField: string, sortRule?: string) => {
    setSearchParams((prevParams: SearchParams) => ({
      ...prevParams,
      sortField: sortField,
      sortRule: sortRule,
    }));
  };

  const buildQueryString = useCallback(() => {
    if (!searchParams) {
      return 'limit=20&page=1';
    }
    if (
      searchParams.beginDate !== undefined &&
      searchParams.endDate !== undefined &&
      parseISO(searchParams.beginDate) > parseISO(searchParams.endDate)
    ) {
      return { error: 'searchError_createAt' };
    }
    if (
      searchParams.grandTotalLower !== undefined &&
      searchParams.grandTotalUpper !== undefined &&
      Number(searchParams.grandTotalLower) > Number(searchParams.grandTotalUpper)
    ) {
      return { error: 'searchError_transactionAmount' };
    }
    if (
      searchParams.expirationDateFrom !== undefined &&
      searchParams.expirationDate !== undefined &&
      parseISO(searchParams.expirationDateFrom) > parseISO(searchParams.expirationDate)
    ) {
      return { error: 'searchError_expirationDate' };
    }

    searchParams.sortField = searchParams.sortField ?? undefined;
    searchParams.sortRule = searchParams.sortRule ?? undefined;
    searchParams.page = searchParams.page ?? 1;
    searchParams.limit = searchParams.limit
      ? searchParams.limit > 20
        ? 20
        : searchParams.limit
      : 20;

    return Object.keys(searchParams)
      .filter((key) => searchParams[key as keyof SearchParams])
      .map((key) => key + '=' + searchParams[key as keyof SearchParams])
      .join('&');
  }, [searchParams]);

  const fetchTransactions = useCallback(async (queryString: string) => {
    setLoading(true);
    const { data, totalAmount, totalItemCount, totalPageCount } = await TransactionService.findAll(
      queryString,
    );
    setTransactions(data);
    setTotalAmount(totalAmount);
    setTotalItemCount(totalItemCount);
    setTotalPageCount(totalPageCount);
    setLoading(false);
  }, []);

  const search = useCallback(() => {
    const queryString = buildQueryString();
    if (typeof queryString === 'object' && 'error' in queryString) {
      setError(queryString.error);
      return;
    }
    fetchTransactions(queryString);
  }, [buildQueryString, fetchTransactions]);

  const handleSubmit = (params: SearchParams) => {
    setSearchParams(params);
  };

  useEffect(() => {
    if (account) {
      search();
    }
  }, [search, account]);

  useEffect(() => {
    if (error) {
      alert(t(error));
      setError('');
    }
  }, [error, t]);

  return (
    <div id='main'>
      {<ProgressBar loading={loading} setLoading={setLoading} progress={0} />}
      <SearchForm onSubmit={handleSubmit} />
      <Pagination
        totalPage={totalPageCount ?? 0}
        currentPage={searchParams?.page ?? 1}
        pageRange={4}
        onChange={setPage}
      />
      <div className='d-flex justify-content-between'>
        {t('totalItemCount')} {totalItemCount?.toLocaleString()} {t('itemUnit')},
        {t('totalPageCount')} {totalPageCount?.toLocaleString()} {t('pageUnit')}
        <span className='pe-1'>
          {t('totalGrandTotal')}(NT$){totalAmount?.toLocaleString()}
        </span>
      </div>
      <TransactionTable
        isAdmin={account?.isAdmin}
        currentSortField={searchParams?.sortField ?? ''}
        currentSortRule={searchParams?.sortRule ?? ''}
        transactions={transactions ?? []}
        setSort={setSort}
      />
    </div>
  );
};
