import { useTranslation } from 'react-i18next';
import { ExtraInfo } from 'src/types';
import { getProductName } from 'src/utils';

export const ExtraInfoTable = ({ extraInfo }: { extraInfo: ExtraInfo[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {extraInfo.map(({ sku, quantity }, index) => {
        return (
          <div key={index} className='mb-2'>
            {`${t(`sku:${getProductName(sku)}.${sku}`)}  ${quantity.toLocaleString()}`}
            <br />
          </div>
        );
      })}
    </>
  );
};
