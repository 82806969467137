import {
  Account,
  Customer,
  License,
  PagedFilter,
  PurchaseOrder,
  SalesOrder,
  Transaction,
} from 'src/types';
import { axiosFactory } from './axiosSetup';

export const TransactionService = {
  findAll: async (
    queryString: string,
  ): Promise<{
    data: Transaction[];
    totalAmount: number;
    totalItemCount: number;
    totalPageCount: number;
  }> => {
    const { data, headers } = await axiosFactory().get<Transaction[]>(
      `/api/transactions?${queryString}`,
    );
    return {
      data: data,
      totalAmount: Number(headers['x-sum']),
      totalItemCount: Number(headers['x-total-count']),
      totalPageCount: Number(headers['x-page-count']),
    };
  },
};

export const SalesOrderService = {
  get: async (salesOrderId: string): Promise<SalesOrder> => {
    const { data } = await axiosFactory().get<SalesOrder>(`/api/salesOrders/${salesOrderId}`);
    return data;
  },
};

export const AffiliateService = {
  findAll: async (): Promise<Account[]> => {
    const { data } = await axiosFactory().get<Account[]>(`/api/affiliates`);
    return data;
  },
};

export const PurchaseService = {
  createAddon: async (instanceId: string, purchaseOrder: PurchaseOrder): Promise<boolean> => {
    const response = await axiosFactory().post(
      `/api/purchaseOrders?instanceId=${instanceId}&action=addon`,
      purchaseOrder,
    );
    const location = response.headers['location'];
    if (location) {
      window.location.href = location;
      return true;
    }

    return false;
  },
  reNew: async (instanceId: string, purchaseOrder: PurchaseOrder): Promise<boolean> => {
    const response = await axiosFactory().post(
      `/api/purchaseOrders?instanceId=${instanceId}&action=renew`,
      purchaseOrder,
    );
    const location = response.headers['location'];
    if (location) {
      window.location.href = location;
      return true;
    }

    return false;
  },
};

export const CustomerService = {
  findAll: async (
    filter: PagedFilter,
  ): Promise<{ data: Customer[]; totalItemCount: number; totalPageCount: number }> => {
    const { data, headers } = await axiosFactory().get<Customer[]>(
      `/api/customers?page=${filter.page}&limit=${filter.limit}`,
    );
    return {
      data: data,
      totalItemCount: headers['x-total-count'],
      totalPageCount: headers['x-page-count'],
    };
  },
  get: async (customerId: string): Promise<Customer> => {
    const { data } = await axiosFactory().get<Customer>(`/api/customers/${customerId}`);
    return data;
  },
};

export const LicenseService = {
  findByEmail: async (email: string): Promise<License[]> => {
    const { data } = await axiosFactory().get<License[]>(`/api/licenses/?email=${email}`);
    return data;
  },
};

export const UserService = {
  login: async (returnUrl?: string): Promise<void> => {
    const params = returnUrl ? `?returnUrl=${returnUrl}` : '';
    const loginUrl = `/api/account/login${params}`;
    window.location.assign(loginUrl);
  },
  logout: async (returnUrl?: string): Promise<void> => {
    const params = returnUrl ? `?returnUrl=${returnUrl}` : '';
    const logoutUrl = `/api/account/logout${params}`;
    window.location.assign(logoutUrl);
  },
  getUser: async (): Promise<Account | null> => {
    const { data } = await axiosFactory().get<Account>('/api/account/me');
    return data;
  },
};
