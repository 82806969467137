import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SalesOrderService } from 'src/apis';
import { ProgressBar } from 'src/components';
import { useAccount } from 'src/context';
import { SalesOrder as SalesOrderType } from 'src/types';
import { SalesOrderTable } from './salesOrderTable';

export const SalesOrder = () => {
  let params = useParams();
  const { account } = useAccount();
  const [salesOrder, setSalesOrder] = useState<SalesOrderType>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchSalesOrder = useCallback(async () => {
    setLoading(true);
    if (!params.salesOrderId) {
      return;
    }
    const data = await SalesOrderService.get(params.salesOrderId!);
    setSalesOrder(data);
    setLoading(false);
  }, [params.salesOrderId]);

  useEffect(() => {
    if (account) {
      fetchSalesOrder();
    }
  }, [params.salesOrderId, account, fetchSalesOrder]);

  return (
    <div id='main'>
      <ProgressBar loading={loading} setLoading={setLoading} progress={0} />
      <Button variant='light' onClick={() => navigate(-1)}>
        {t('back')}
      </Button>
      {!loading && <SalesOrderTable salesOrder={salesOrder} />}
    </div>
  );
};
