import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const AccessDenied = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Alert
      variant='danger'
      className='text-center position-absolute top-50 start-50 translate-middle p-5'
      onClose={() => navigate('/transactions')}
      dismissible
    >
      <Alert.Heading className='text-center'>{t('accessDenied')}</Alert.Heading>
      <p>{t('accessDenied_text')}</p>
    </Alert>
  );
};
