import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

void i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'locale',
      lookupCookie: 'locale',
      lookupLocalStorage: 'locale',
    },
    fallbackLng: {
      zh: ['zh-Hant', 'en'],
      'zh-TW': ['zh-Hant', 'en'],
      default: ['en'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        translation: require('./locales/en/locale.json'),
        sku: require('./locales/en/sku.json'),
      },
      ja: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        translation: require('./locales/ja/locale.json'),
        sku: require('./locales/ja/sku.json'),
      },
      'zh-Hant': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        translation: require('./locales/zh-Hant/locale.json'),
        sku: require('./locales/zh-Hant/sku.json'),
      },
    },
  });

export default i18n;
