import { Customer } from './customerTypes';

export interface SalesOrder {
  id: number;
  incrementId: string;
  createdAt: Date;
  currency: string;
  customer: Customer;
  subtotal: number;
  commission: number;
  program: string;
  discountAmount: number;
  grantTotal: number;
  items: SalesOrderItem[];
  paymentMethod: string;
  status: string;
  userName: string;
}

export interface SalesOrderItem {
  discountAmount: number;
  name: string;
  price: number;
  quantity: number;
  sku: string;
  activationDate: string;
  expirationDate: string;
  url: string;
}

export const orderStatus = [
  'pending',
  'pending_payment',
  'installing',
  'installed',
  'complete',
  'canceled',
];
