import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Option = {
  nextStep: boolean;
  totalGrandTotal: string;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
};

export const ModalFooter = ({
  nextStep,
  totalGrandTotal,
  handleNextStep,
  handlePreviousStep,
  handleConfirm,
  handleCancel,
}: Option) => {
  const { t } = useTranslation();

  return nextStep ? (
    <>
      <Button className='me-auto' variant='secondary' onClick={handlePreviousStep}>
        {t('previous')}
      </Button>
      <span>
        {t('totalGrandTotal')}：{totalGrandTotal} {t('dollars')}
      </span>
      <Button className='ms-4' variant='primary' onClick={handleConfirm}>
        {t('confirmAddon')}
      </Button>
    </>
  ) : (
    <>
      <Button className='me-auto' variant='secondary' onClick={handleCancel}>
        {t('cancel')}
      </Button>
      <Button variant='primary' onClick={handleNextStep}>
        {t('next')}
      </Button>
    </>
  );
};
