import { TFunction } from 'i18next';
import { getProductName } from 'src/utils';

export interface PurchaseOrder {
  instanceId: string;
  items: PurchaseOrderItem[];
  endDate: string;
}

export interface PurchaseOrderItem {
  sku: string;
  price: number;
  quantity: number;
  description?: string;
}
export const genDescript = (
  t: TFunction<'translation', undefined, 'translation'>,
  sku: string,
  qty: number,
  price: number,
) =>
  `${t(`sku:${getProductName(sku)}.${sku}`)}(${t('itemQuantity')}:${qty})(${t(
    'amount',
  )}:$${price})`;
