import { format, parseISO } from 'date-fns';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'src/components';
import { orderStatus, paymentMethods, SalesOrder } from 'src/types';

export const SalesOrderTable = ({ salesOrder }: { salesOrder?: SalesOrder }) => {
  const { t } = useTranslation();

  if (!salesOrder) {
    return <h1>{t('salesOrderError')}</h1>;
  }

  const SalesOrderItemsTable = () => {
    const showActivationDate =
      salesOrder.items === undefined
        ? false
        : salesOrder.items.filter((x) => x.activationDate).length > 0;

    return (
      <Table className='table table-hover table-bordered'>
        <thead data-toggle='collapse' data-target='#detail'>
          <tr>
            <th>{t('sku')}</th>
            <th>{t('itemName')}</th>
            {showActivationDate && <th>{t('startAndExpirationDate')}</th>}
            <th>{t('itemQuantity')}</th>
            <th className='text-end'>{t('discount')}(NT$)</th>
            <th className='text-end'>{t('price')}(NT$)</th>
          </tr>
        </thead>
        <tbody id='detail'>
          {salesOrder?.items?.map(
            (
              { sku, name, activationDate, expirationDate, quantity, discountAmount, price },
              index,
            ) => {
              return (
                <tr key={index}>
                  <td>{sku}</td>
                  <td>{name}</td>
                  {activationDate && (
                    <td>
                      {format(parseISO(activationDate), 'yyyy-MM-dd')}~
                      {expirationDate && format(parseISO(expirationDate), 'yyyy-MM-dd')}
                    </td>
                  )}
                  <td>{quantity}</td>
                  <td className='text-end'>{discountAmount.toLocaleString()}</td>
                  <td className='text-end'>{price.toLocaleString()}</td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    );
  };

  const paymentMethod = paymentMethods.find((x) => x === salesOrder.paymentMethod) ?? 'creditCard';
  const status = orderStatus.find((x) => x === salesOrder.status) ?? salesOrder.status;

  return (
    <div className='table-responsive'>
      <Table className='table table-hover'>
        <tbody>
          <TableRow name={t('orderNumber')} value={salesOrder.incrementId} />
          <TableRow
            name={t('createdAt')}
            value={
              !salesOrder.createdAt
                ? undefined
                : format(parseISO(salesOrder.createdAt.toString()), 'yyyy-MM-dd')
            }
          />
          <TableRow name={t('customerCompany')} value={salesOrder.customer?.company} />
          <TableRow name={t('customerName')} value={salesOrder.customer?.name} />
          <TableRow name={t('customerEmail')} value={salesOrder.customer?.email} />
          <TableRow name={t('userName')} value={salesOrder.userName} />
          <TableRow name={t('orderDetail')} value={<SalesOrderItemsTable />} />
          <TableRow name={`${t('subtotal')}(NT$)`} value={salesOrder.subtotal.toLocaleString()} />
          <TableRow
            name={`${t('discount')}(NT$)`}
            value={
              salesOrder.grantTotal !== undefined && salesOrder.subtotal !== undefined
                ? (salesOrder.grantTotal - salesOrder.subtotal).toLocaleString()
                : undefined
            }
          />
          <TableRow
            name={`${t('grandTotal')}(NT$)`}
            value={salesOrder.grantTotal.toLocaleString()}
          />
          <TableRow name={t('paymentMethod')} value={t<string>(`${paymentMethod}`)} />
          <TableRow name={t('program')} value={salesOrder.program} />
          <TableRow name={t('status')} value={t<string>(`${status}`)} />
        </tbody>
      </Table>
    </div>
  );
};
