import { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { UserService } from 'src/apis';
import { Account } from 'src/types';

type ContextType = {
  account: Account;
};

export const AppContextProvider = () => {
  const [account, setAccount] = useState<Account>();

  const login = async () => {
    const user = await UserService.getUser();
    if (user) {
      user.isAdmin = user.role === 'Administrator';
      setAccount(user);
    }
  };

  useEffect(() => {
    login();
  }, []);

  return <Outlet context={{ account }} />;
};

export const useAccount = () => {
  return useOutletContext<ContextType>();
};
