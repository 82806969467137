import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, addMonths, addYears } from 'date-fns';
import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { AffiliateService } from 'src/apis';
import { DateRangePickerInput, FormNumberInput, FormTextInput } from 'src/components';
import { useAccount } from 'src/context';
import { Account, SearchParams } from 'src/types';

export const SearchForm = ({ onSubmit }: { onSubmit: (params: SearchParams) => void }) => {
  const [params, setParams] = useState<SearchParams>();
  const [enableExpiration, setEnableExpiration] = useState<boolean>();
  const [extraQuery, setExtraQuery] = useState(false);
  const [affiliates, setAffiliates] = useState<Account[]>();
  const { account } = useAccount();
  const { t } = useTranslation();
  const today = new Date();
  const pastDateRanges = [
    { label: 'lastWeek', startDate: addDays(today, -7) },
    { label: 'lastMonth', startDate: addMonths(today, -1) },
    { label: 'lastThreeMonth', startDate: addMonths(today, -3) },
    { label: 'lastSixMonth', startDate: addMonths(today, -6) },
    { label: 'lastYear', startDate: addYears(today, -1) },
    { label: 'customDateRange', startDate: today },
  ];
  const futureDateRanges = [
    { label: 'nextWeek', endDate: addDays(today, 7) },
    { label: 'nextMonth', endDate: addMonths(today, 1) },
    { label: 'nextThreeMonth', endDate: addMonths(today, 3) },
    { label: 'nextSixMonth', endDate: addMonths(today, 6) },
    { label: 'nextYear', endDate: addYears(today, 1) },
    { label: 'customDateRange', endDate: today },
  ];

  const handleChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target;
    setParams((params) => ({
      ...params,
      [name]: value,
    }));
  };

  const handleSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    onSubmit(params!);
  };

  const setCreateAt = useCallback((startDate: string, endDate: string) => {
    setParams((params) => ({
      ...params,
      beginDate: startDate,
      endDate: endDate,
    }));
  }, []);

  const setExpirationDate = useCallback((startDate: string, endDate: string) => {
    setParams((params) => ({
      ...params,
      expirationDateFrom: startDate,
      expirationDate: endDate,
    }));
  }, []);

  const setAccountId = (
    newValue: SingleValue<{
      value: number;
      label: string;
    }>,
  ) => {
    setParams((params) => ({
      ...params,
      accountId: newValue?.value.toString(),
    }));
  };

  const clearTextInput = (fieldName: string) => {
    setParams({ ...params, [fieldName]: '' });
  };

  const fetchAffiliates = useCallback(async () => {
    const data = await AffiliateService.findAll();
    setAffiliates(data);
  }, []);

  useEffect(() => {
    if (account?.isAdmin) {
      fetchAffiliates();
    }
  }, [account, fetchAffiliates]);

  useEffect(() => {
    setEnableExpiration(undefined);
    if (params?.beginDate || params?.endDate) {
      setExpirationDate('', '');
      setEnableExpiration(false);
    }
    if (params?.expirationDateFrom || params?.expirationDate) {
      setCreateAt('', '');
      setEnableExpiration(true);
    }
    if (
      params?.companyName ||
      params?.accountId ||
      params?.customerEmail ||
      params?.customerName ||
      params?.grandTotalLower ||
      params?.grandTotalUpper
    ) {
      setEnableExpiration(false);
    }
  }, [
    params?.beginDate,
    params?.endDate,
    params?.expirationDateFrom,
    params?.expirationDate,
    params?.companyName,
    params?.accountId,
    params?.customerEmail,
    params?.customerName,
    params?.grandTotalLower,
    params?.grandTotalUpper,
    setCreateAt,
    setExpirationDate,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='mb-3'>
        <Form.Label column md='auto'>
          {t('createdAt')}
        </Form.Label>
        <Col md='auto'>
          <DateRangePickerInput
            setDate={setCreateAt}
            maxDate={new Date()}
            staticRanges={pastDateRanges}
            disabled={enableExpiration === undefined ? false : enableExpiration}
          />
        </Col>
        <ButtonGroup className='col-md-auto'>
          <Button variant='primary' type='submit'>
            <FontAwesomeIcon icon={faSearch} />
            {t('search')}
          </Button>
          <Button variant='primary' onClick={() => setExtraQuery(!extraQuery)}>
            <FontAwesomeIcon icon={faCaretDown} />
          </Button>
        </ButtonGroup>
      </Row>
      <div className={!extraQuery ? 'd-none' : ''}>
        <fieldset disabled={enableExpiration === undefined ? false : enableExpiration}>
          <Row className='mb-3'>
            <Form.Label column md='auto'>
              {t('customerEmail')}
            </Form.Label>
            <FormTextInput
              type='email'
              name='customerEmail'
              placeholder={t('customerEmail_placeholder')}
              value={params?.customerEmail ?? ''}
              onChange={handleChange}
              showClearButton={!!params?.customerEmail}
              onClick={clearTextInput}
            />
            <Form.Label column md='auto'>
              {t('customerName')}
            </Form.Label>
            <FormTextInput
              type='text'
              name='customerName'
              placeholder={t('customerName_placeholder')}
              value={params?.customerName ?? ''}
              onChange={handleChange}
              showClearButton={!!params?.customerName}
              onClick={clearTextInput}
            />
            <Form.Label column md='auto'>
              {t('customerCompany')}
            </Form.Label>
            <FormTextInput
              type='text'
              name='companyName'
              placeholder={t('customerCompany_placeholder')}
              value={params?.companyName ?? ''}
              onChange={handleChange}
              showClearButton={!!params?.companyName}
              onClick={clearTextInput}
            />
          </Row>
          <Row className='mb-3'>
            <Form.Label column md='auto'>
              {t('transactionAmount')}
            </Form.Label>
            <Col md='auto'>
              <FormNumberInput
                name='grandTotalLower'
                value={params?.grandTotalLower ?? 0}
                onChange={handleChange}
              />
            </Col>
            _
            <Col md='auto'>
              <FormNumberInput
                name='grandTotalUpper'
                value={params?.grandTotalUpper ?? 0}
                onChange={handleChange}
              />
            </Col>
            {account?.isAdmin && (
              <>
                <Form.Label column md='auto'>
                  {t('accountName')}
                </Form.Label>
                <Col md='3'>
                  <Select
                    isClearable={true}
                    options={affiliates?.map(({ id, name, email }) => {
                      return { value: id, label: `${name} ${email}` };
                    })}
                    onChange={setAccountId}
                    placeholder={t('accountName_placeholder')}
                  />
                </Col>
              </>
            )}
          </Row>
        </fieldset>
        <hr />
        <Row className='mb-3'>
          <Form.Label column md='auto'>
            {t('expirationDate')}
          </Form.Label>
          <Col md='auto'>
            <DateRangePickerInput
              setDate={setExpirationDate}
              minDate={new Date()}
              staticRanges={futureDateRanges}
              disabled={enableExpiration === undefined ? false : !enableExpiration}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};
