import { ReactNode } from 'react';

type RowOption = {
  name: string;
  value?: string | number | ReactNode;
};

export const TableRow = ({ name, value }: RowOption) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{value}</td>
    </tr>
  );
};
