import { addDays, format, parseISO } from 'date-fns';
import { produce } from 'immer';
import React, { BaseSyntheticEvent, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PurchaseService } from 'src/apis';
import {
  DatePickerInput,
  ExtraInfoForm,
  ModalFooter,
  ModuleForm,
  PlanForm,
  PointAddonForm,
} from 'src/components';
import { License, PurchaseOrder, genDescript } from 'src/types';

type Option = {
  currentLicense: License;
  singleStep: boolean;
  show: boolean;
  hide: () => void;
  setCheckout: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RenewModal = ({ currentLicense, singleStep, show, hide, setCheckout }: Option) => {
  const [nextStep, setNextStep] = useState(false);
  const [license, setLicense] = useState(currentLicense);
  const [invalid, setInvalid] = useState({ plan: false, renewEndDate: false });
  const excludeExtraInfo = singleStep ? ['vital_crm_sms', 'vital_crm_point'] : [];
  const { t } = useTranslation();
  const initPurchaseOrder = singleStep
    ? {
        instanceId: currentLicense.instanceId,
        items: [
          // 加購後的續約方案要換成 renew
          {
            sku: currentLicense.plan.replace('addon', 'renew'),
            price: 0,
            quantity: 1,
            description: genDescript(t, license.plan, 1, 0),
          },
          ...license.modules
            .filter((x) => x.enabled)
            .map((x) => ({
              sku: x.sku,
              price: 0,
              quantity: 1,
              description: genDescript(t, x.sku, 1, 0),
            })),
          ...license.extraInfo
            .filter((x) => x.quantity > 0)
            .map((x) => ({
              sku: x.sku,
              price: 0,
              quantity: x.quantity,
              description: genDescript(t, x.sku, x.quantity, 0),
            })),
        ],
        endDate: '',
      }
    : {
        instanceId: currentLicense.instanceId,
        items: [],
        endDate: '',
      };
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>(initPurchaseOrder);
  const handleChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target;
    if (name === 'plan') {
      setValid(value === undefined ? value : value ? false : true);
    }
    setLicense(
      produce((draft) => {
        if (name === 'plan') {
          draft.plan = value;
          return;
        }
        const module = draft?.modules.find((module) => module.sku === name);
        if (module) {
          module.enabled = !module.enabled;
          return;
        }
        const extraInfoItem = draft?.extraInfo.find((item) => item.sku === name);
        if (extraInfoItem) {
          extraInfoItem!.quantity = value;
          return;
        }
      }),
    );
  };

  const setEndDate = (endDate: Date) => {
    setValid(undefined, false);
    setPurchaseOrder((prev: PurchaseOrder) => ({
      ...prev,
      endDate: format(endDate, 'yyyy-MM-dd'),
    }));
  };

  const setValid = (plan?: boolean, renewEndDate?: boolean) => {
    const fields = {
      plan: plan === undefined ? invalid.plan : plan,
      renewEndDate: renewEndDate === undefined ? invalid.renewEndDate : renewEndDate,
    };
    setInvalid(fields);
  };

  const handleNextStep = () => {
    if (singleStep) {
      return;
    }

    if (!license.plan) {
      setValid(true);
      return;
    }

    setValid(false);
    setPurchaseOrder((prev: PurchaseOrder) => ({
      ...prev,
      instanceId: license.instanceId,
      items: [
        {
          sku: license.plan,
          price: 0,
          quantity: 1,
          description: genDescript(t, license.plan, 1, 0),
        },
        ...license.modules
          .filter((x) => x.enabled)
          .map((x) => ({
            sku: x.sku,
            price: 0,
            quantity: 1,
            description: genDescript(t, x.sku, 1, 0),
          })),
        ...license.extraInfo
          .filter((x) => x.quantity > 0)
          .map((x) => ({
            sku: x.sku,
            price: 0,
            quantity: x.quantity,
            description: genDescript(t, x.sku, x.quantity, 0),
          })),
      ],
    }));
    setNextStep(true);
  };

  const handlePriceChange = (event: BaseSyntheticEvent) => {
    const { name, value } = event.target;
    setPurchaseOrder(
      produce((draft) => {
        const item = draft?.items.find((item) => item.sku === name);
        if (item) {
          item.price = value;
          item.description = genDescript(t, item.sku, item.quantity, item.price);
        }
      }),
    );
  };

  const handleHide = () => {
    hide();
    setNextStep(false);
  };

  const submit = async () => {
    if (!purchaseOrder.endDate) {
      setValid(undefined, true);
      return;
    }

    setValid(undefined, false);
    const success = await PurchaseService.reNew(license.instanceId, purchaseOrder);
    if (success) {
      handleHide();
      setCheckout(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      backdrop='static'
      keyboard={false}
      size='lg'
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('renew')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(singleStep || nextStep) && (
          <Form className='mb-5'>
            <Row>
              <Form.Label column md='auto' className='input-required'>
                {t('renewEndDate')}
              </Form.Label>
              <Col xs='4'>
                <DatePickerInput
                  date={purchaseOrder.endDate ? parseISO(purchaseOrder.endDate) : undefined}
                  minDate={addDays(parseISO(license.expirationDate), 1)}
                  setDate={setEndDate}
                  invalid={invalid.renewEndDate}
                />
              </Col>
            </Row>
          </Form>
        )}
        {t('plan')}
        <hr />
        <PlanForm
          plan={license.plan.replace('addon', 'renew')}
          modifiableQuantity={!(singleStep || nextStep)}
          items={purchaseOrder.items}
          optionPlans={['vital_crm_renew_sme', 'vital_crm_renew_enterprise']}
          invalid={invalid.plan}
          handleChange={handleChange}
          handlePriceChange={handlePriceChange}
        />
        {t('module')}
        <hr />
        <ModuleForm
          modules={license.modules}
          isNextStep={nextStep}
          modifiableQuantity={!(singleStep || nextStep)}
          items={purchaseOrder.items}
          handleChange={handleChange}
          handlePriceChange={handlePriceChange}
        />
        {t('extraInfo')}
        <hr />
        <ExtraInfoForm
          extraInfo={license.extraInfo.filter((x) => !excludeExtraInfo.some((y) => y === x.sku))}
          isNextStep={nextStep}
          showNoExtraInfo={license?.extraInfo.every((x) => nextStep && x.quantity <= 0)}
          modifiableQuantity={!(singleStep || nextStep)}
          items={purchaseOrder.items}
          handleChange={handleChange}
          handlePriceChange={handlePriceChange}
        />
        {singleStep && (
          <>
            {t('pointAddon')}
            <hr />
            <PointAddonForm
              extraInfo={license.extraInfo.filter((x) => excludeExtraInfo.some((y) => x.sku === y))}
              items={purchaseOrder.items}
              handleChange={handleChange}
              handlePriceChange={handlePriceChange}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {singleStep ? (
          <>
            <Button className='me-auto' variant='secondary' onClick={handleHide}>
              {t('cancel')}
            </Button>
            <span>
              {t('totalGrandTotal')}：
              {purchaseOrder.items
                .map((x) => x.price)
                ?.reduce((prev, current) => Number(prev) + Number(current), 0)
                .toLocaleString()}{' '}
              {t('dollars')}
            </span>
            <Button className='ms-4' variant='primary' onClick={submit}>
              {t('confirmRenew')}
            </Button>
          </>
        ) : (
          <ModalFooter
            nextStep={nextStep}
            totalGrandTotal={purchaseOrder.items
              .map((x) => x.price)
              ?.reduce((prev, current) => Number(prev) + Number(current), 0)
              .toLocaleString()}
            handleNextStep={handleNextStep}
            handlePreviousStep={() => setNextStep(false)}
            handleConfirm={submit}
            handleCancel={handleHide}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
